<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('Bases1C')"
    @cancel="onCancel('Bases1C')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'Bases1CEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Bases1C',
      pageHeader: 'Редактирование базы 1С',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Базы 1С',
          route: { name: 'Bases1C' },
        },
        {
          text: 'Редактирование базы 1С',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      customers: (state) => state.common.customers,
      bases1CTypes: (state) => state.common.bases1CTypes,
      bases1CUpdateTypes: (state) => state.common.bases1CUpdateTypes,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'created',
              label: 'Дата создания',
              disabled: true,
            },
            {
              type: 'select',
              key: 'customerId',
              label: 'Клиент',
              list: this.customers.data, // Common/Customers
              disabled: true,
            },
            {
              type: 'text',
              key: 'name',
              label: 'Имя базы (так будет названа папка на диске)',
              disabled: true,
            },
            // {
            //   type: 'checkbox',
            //   key: 'createDemo',
            //   label: 'Загрузить демонстрационные данные',
            // },
            {
              type: 'text',
              key: 'text',
              label: 'Отображаемое имя (так будет называться в списке баз)',
            },
            {
              type: 'select',
              key: 'baseTypeId',
              label: 'Тип информационной базы',
              list: this.bases1CTypes.data, // Common/Bases1CTypes
            },
            {
              type: 'checkbox',
              key: 'isServerBase',
              label: 'Серверная база',
            },
            {
              type: 'text',
              key: 'releaseNum',
              label: 'Релиз',
            },
            {
              type: 'select',
              key: 'autoupdate',
              label: 'Обновление',
              list: this.bases1CUpdateTypes.data, // Common/Bases1CUpdateTypes
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const customers = this.getCommonList({ name: 'Customers' });
    const bases1CTypes = this.getCommonList({ name: 'Bases1CTypes' });
    const bases1CUpdateTypes = this.getCommonList({
      name: 'Bases1CUpdateTypes',
    });
    const item = this.getItem({ name: this.controllerName, id: this.id }).then(
      () => {
        this.form = this.lodash.cloneDeep(this.item.data);
      }
    );

    Promise.all([customers, bases1CTypes, bases1CUpdateTypes, item]).finally(
      () => {
        this.isLoadingPage = false;
      }
    );
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
